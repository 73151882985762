// Imports
// ------
import React from 'react';

// Component
// ------
export default class NotFoundPage extends React.Component {
    render() {
        return (
            <div>
                <h1>NOT FOUND</h1>
                <p>
                    ༼ つ ◕_◕ ༽つ //
                </p>
            </div>
        );
    }
}
